@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Noto+Serif:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,300;0,400;0,700;1,200;1,400&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;

  background-image: linear-gradient(
      rgba(186, 186, 186, 0.8),
      rgba(63, 63, 63, 0.8)
    ),
    url("./assets/ingenga.jpg");
  background-size: 140%;
  background-position: center;
  background-attachment: fixed;
}
h1 {
  font-weight: 300;
}

.button {
  padding: 12px;
  // background-color: white;
  background-color: rgb(176, 191, 178);
  color: white;
  text-decoration: none;

  border: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: rgb(176, 191, 178);
  }
}

.app-content {
  display: flex;

  &__left {
    width: 250px;
    transition: 300ms;
  }

  &__right {
    flex: 1;
  }

  &--mobile {
    &__left {
      width: 0;
    }
  }
}

.page-contents {
  animation: fadeIn 0.5s ease-in-out;
  min-height: 100vh;
}

.white-panel {
  background-color: whitesmoke;
  padding: 20px;
  margin: 20px;
}

.homepage-posts-container {
  margin-left: 100px;
  margin-right: 20px;
  flex: 1;
}

.music-details {
  &__header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
  }
  &__score-image {
    height: 300px;

    & img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__media-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__download-button {
    margin: 30px 0;
  }

  &__short-description {
    font-weight: 100;
  }

  &__video {
    width: 40%;
    min-width: 350px;
    margin-right: 20px;
    & iframe {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
  }
  &__soundcloud {
    flex: 1;
    display: flex;
    flex-direction: column;
    & iframe {
      width: 100%;
      height: 120px;
      object-fit: cover;
    }
  }
}

@media (max-width: 1000px) {
  .music-details {
    &__video {
      width: 100%;
      min-width: 100%;
      margin-right: 0;
    }
    &__header {
      flex-direction: column;
    }
  }
}

.search-container {
  padding: 20px;
}

.music-search-input {
  padding: 10px;
  width: 300px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  &:focus {
    padding: 13px;
    transition: 300ms;
  }
}

.light-background {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
}

@media (max-width: 900px) {
  .app-content {
    &__left {
      width: 0;
    }
  }

  @media (max-width: 900px) {
    .light-background {
      padding: 5px;
    }
    .music-details {
      &__video {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
      }
      & iframe {
        width: 100%;
      }
      &__nav {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
  }
  .page-contents {
    padding: 10px;
  }

  .homepage-posts-container {
    margin-left: 0;
    margin-right: 0;
  }

  // .music-search-input {
  //   width: 120px;
  // }
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 700px) {
  .music-details {
    flex-direction: column-reverse;

    &__header {
      font-size: 0.9rem;
    }
  }
}

.performance-content-section {
  display: flex;
}

.performance-page-videos {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}

.audio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__card {
    width: 30%;
    margin: 20px;
    text-align: left;

    &__link {
      color: white;
    }
  }
}

@media (max-width: 1200px) {
  .performance-content-section {
    flex-direction: column-reverse;
  }
  .audio-container {
    &__card {
      width: 45%;
    }
  }
}

.biog-page {
  background-color: whitesmoke;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    width: 50%;
    margin: auto;
  }
}
@media (max-width: 700px) {
  .audio-container {
    &__card {
      width: 100%;
      margin: 20px 0;
    }
  }
  .biog-page {
    & img {
      width: 100%;
    }
  }
}

.slide-show {
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: auto;

  & img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation: fadeIn 300ms ease;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
