.post-item-container {
  background-color: whitesmoke;
  height: 600px;
  overflow: hidden;
  margin: 20px 0;
  position: relative;
  // width: 100%;
}

.post-item {
  position: relative;
  max-width: 600px;
  padding: 20px;
  margin: 20px auto;
  // opacity: 0.5;
  transition: 200ms;
  overflow: hidden;
  // filter: saturate(0);
  h2,
  h1 {
    font-weight: 400;
    margin-bottom: -20px;
  }

  &__header {
    display: flex;

    &__left {
      width: 60%;
    }

    &__right {
      flex: 1;
      & img {
        max-width: 400px;
        max-height: 400px;
      }
    }
  }

  &__body {
    width: 100%;
    & img {
      max-width: 100%;
    }
  }

  & iframe {
    display: block;
    margin: 30px auto;
    max-width: 100% !important;
  }
}

.fade-out {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 340px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 300;
}

.color {
  filter: saturate(1);
  opacity: 1;
  transition: 500ms;
}

.more-button {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 500;
}

@media (max-width: 900px) {
  .post-item {
    max-width: 100%;
    &__header {
      flex-direction: column;
      &__right {
        flex: 1;
        & img {
          max-width: 100%;
          // max-height: 400px;
        }
      }
    }
    &__left {
      width: 100%;
    }
    &__right {
      & img {
        width: 100%;
      }
    }
  }
}
