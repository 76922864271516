.compositions-list-item {
  position: relative;
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 30px;
  background-color: whitesmoke;
  margin: 20px auto;
  background-size: cover;
  width: 70%;
  height: fit-content;
  text-transform: lowercase;
  text-align: center;
  letter-spacing: 0.2rem;
  & img {
    height: 350px;
    margin-bottom: 20px;
  }

  & h3 {
    font-weight: 100;
    margin-top: -10px;
    font-size: 1.3rem;
  }

  &--button {
    position: absolute;
    bottom: 8px;
    right: 16px;
    color: black;
    border: none;
    outline: none;
    background-color: transparent;
  }
}

.show {
  opacity: 1;
  transition: 200ms;
}

@media (max-width: 1000px) {
  .compositions-list-item {
    // height: fit-content;
    justify-content: center;
  }
}
