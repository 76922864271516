.header-container {
  position: relative;
  // padding: 20px;
  z-index: 1000;
  width: 100%;
  padding-bottom: 40px;

  &__content {
    display: flex;
    justify-content: space-between;
    height: 130px;
    padding: 20px;

    & img {
      max-height: 100%;
      // margin-right: 10px;
    }
  }
  & h1 {
    font-size: 3rem;
    font-weight: 100;
  }

  & h3 {
    font-weight: 400;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding-left: 20px;
  }

  &__text {
    flex: 1;
  }

  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.burger {
  display: none;
}

@media (max-width: 900px) {
  .burger {
    position: sticky;
    top: 0;
    display: block;
    margin-top: 15px;
    margin-bottom: -10px;
  }

  & h1 {
    margin-bottom: 0;
  }
  & h3 {
    margin-top: 5px;
    font-weight: 400;
    letter-spacing: 0.1rem;
    font-size: 0.8rem;
    max-width: 70%;
  }
}

@media (max-width: 700px) {
  .header-container {
    width: 100%;
    & h1 {
      font-size: 2rem;
    }

    & h3 {
      font-weight: 300;
      font-size: 0.8rem;
    }
  }
}
