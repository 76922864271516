.contact-modal {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  background-color: whitesmoke;
  z-index: 300;
  animation: fadeIn 1s ease;
  padding: 30px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  & a {
    color: black;
  }

  &__score {
    height: 300px;
    margin-bottom: -20px;
  }

  &--close-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    & small {
      cursor: pointer;
    }
  }
}

.backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
