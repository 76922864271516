.video-card {
  padding: 10px;
  margin: 20px;
  width: 300px;
  // min-width: 250px;
  // height: 240px;
  position: relative;
  animation: fadeInCard 200ms ease-in-out;

  &__video-container {
    // height: 300px;
    width: 100%;
    overflow: hidden;
  }

  &__link {
    color: white;
  }

  &__link-container {
    position: absolute;
    bottom: -40px;
    height: 50px;
    text-align: center;
  }

  & iframe {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
}

@keyframes fadeInCard {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
