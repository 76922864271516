.side-nav {
  width: 250px;
  position: sticky;
  top: 0;
  z-index: 5000;
  // animation: slideIn 1s ease-in-out;
  // animation-fill-mode: forwards;

  &__links-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(176, 191, 178);
    align-items: flex-end;

    &__link {
      padding: 20px;
      color: black;
      background-color: whitesmoke;
      margin-bottom: 1px;
      text-decoration: none;
      border-radius: 50px 0 0 50px;
      width: 70%;
      transition: 200ms;
      cursor: pointer;

      &:hover {
        background-color: white;
        width: 74%;
        transition: 200ms;
      }
    }
  }
}
.active {
  background-color: white;
  width: 74%;
  transition: 200ms;
  text-decoration: underline;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 900px) {
  .side-nav {
    transform: translateX(-300px);
    transition: 300ms;

    &--open {
      transform: translateX(0px);
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
      height: 100%;
      background-color: rgb(176, 191, 178);
    }
  }
}
